<template>
  <footer>
    <div class="footer-content">
      <div class="content-first">
        <div class="first-item">
          <img v-lazy="ways01" alt="" />
          <div class="item-right">
            <div :style="baseStyle">
              <div class="title">研究·开发</div>
              <div class="sub-title">
                探讨最优合作方案，并负责从设计到交付的所有事项
              </div>
            </div>
            <img src="../assets/svg/footer-right.svg" alt="" />
          </div>
        </div>
        <div class="first-item">
          <img v-lazy="ways02" alt="" />
          <div class="item-right">
            <div :style="baseStyle">
              <div class="title">迭代·优化</div>
              <div class="sub-title">
                展开深度合作，持续迭代策略、优化参数，满足您的需求
              </div>
            </div>
            <img src="../assets/svg/footer-right.svg" alt="" />
          </div>
        </div>
        <div class="first-item">
          <img v-lazy="ways03" alt="" />
          <div class="item-right">
            <div :style="baseStyle">
              <div class="title">上线·共赢</div>
              <div class="sub-title">
                长期稳定打败基准、超越对手，获取超额收益，与您携手共赢
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-second">
        <div class="second-left">
          <img src="../assets/img/logo/logo_white.png" alt="" />
          <div class="contact-phone">
            <div>
              <img src="../assets/svg/phone2.svg" alt="" />
              <div>021-5071 9392</div>
            </div>
            <div>
              <img src="../assets/svg/email.svg" alt="" />
              <div>sales@ft.tech</div>
            </div>
            <div class="last-link">
              <img src="../assets/svg/share-alt.svg" alt="" />
              <a
                target="_blank"
                rel="external"
                class="text-decoration-none"
                href="https://space.bilibili.com/1252977794"
              >
                <img
                  class="icon"
                  src="../assets/img/socialmedia/bilibili_icon.jpg"
                  alt="bilibili"
                />
              </a>
              <a
                target="_blank"
                rel="external"
                class="text-decoration-none"
                href="https://www.toutiao.com/c/user/token/MS4wLjABAAAAE09HIw_UCgpQYl9SGU9MgxbHunCSJex9Cx4sjFhqbIr7Msm_4hB1fosjFog98BV7/?source=feed"
              >
                <img
                  class="icon"
                  src="../assets/img/socialmedia/toutiao_icon.png"
                  alt="toutiao"
                />
              </a>
              <a
                target="_blank"
                rel="external"
                class="text-decoration-none"
                href="https://www.zhihu.com/people/fei-tu-ke-ji"
              >
                <img
                  class="icon"
                  src="../assets/img/socialmedia/zhihu_icon.jpg"
                  alt="zhihu"
                />
              </a>
              <a
                target="_blank"
                rel="external"
                class="text-decoration-none"
                href="https://weibo.com/u/7724045039"
              >
                <img
                  class="icon"
                  src="../assets/img/socialmedia/weibo_icon.jpg"
                  alt="weibo"
                />
              </a>
              <a
                target="_blank"
                rel="external"
                class="text-decoration-none"
                href="https://maimai.cn/company?webcid=17zr73nbo&self=1"
              >
                <img
                  class="icon"
                  src="../assets/img/socialmedia/maimai_icon.png"
                  alt="maimai"
                />
              </a>
            </div>
          </div>
          <div class="contact-img-box">
            <div class="contact-item">
              <div>
                <img v-lazy="weChat" />
              </div>
              <p>公众号</p>
            </div>
            <div class="contact-item">
              <div>
                <img v-lazy="douyin" />
              </div>
              <p>抖音号</p>
            </div>
            <div class="contact-item">
              <div>
                <img v-lazy="shangwu" />
              </div>
              <p>商务合作</p>
            </div>
            <div class="contact-item">
              <div>
                <img v-lazy="pingpai" />
              </div>
              <p>品牌合作</p>
            </div>
            <div class="contact-item">
              <div>
                <img v-lazy="qiuzhi" />
              </div>
              <p>招聘求职</p>
            </div>
          </div>
        </div>
        <div class="second-right">
          <div class="right-item">
            <div>关于我们</div>
            <div>
              <router-link to="/about/company">公司简介</router-link>
            </div>
            <div>
              <router-link to="/about/culture">企业文化</router-link>
            </div>
            <div>
              <router-link to="/about/team">核心团队</router-link>
            </div>
            <div>
              <router-link to="/about/news">新闻动态</router-link>
            </div>
            <div>
              <router-link to="/about/livestream-poster">公司活动</router-link>
            </div>
          </div>
          <div class="right-item">
            <div>产品服务</div>
            <div>
              <router-link to="/product/algorithm">非凸智能算法</router-link>
            </div>
            <div>
              <router-link to="/product/底仓增强">非凸底仓增强算法</router-link>
            </div>
            <div>
              <router-link to="/product/platform">非凸智能交易终端</router-link>
            </div>
            <div>
              <router-link to="/product/bus">非凸升级服务组件</router-link>
            </div>
            <div>
              <router-link to="/business">商务合作</router-link>
            </div>
          </div>
          <div class="right-item">
            <div>招贤纳士</div>
            <div>
              <router-link to="/join">加入我们</router-link>
            </div>
            <div>
              <router-link to="/about/social">社会招聘</router-link>
            </div>
            <div>
              <router-link to="/about/campus">校园招聘</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="content-third">
        <div class="content-third-item">
          <div>上海：徐汇区虹梅路1801号 凯科国际大厦A区28层</div>
          <div>上海：浦东新区世纪大道1196号 世纪汇办公楼T2座11层</div>
        </div>
        <div class="content-third-item">
          <div>北京：东城区青龙胡同1号 歌华大厦A座13层</div>
          <div>深圳：南山区粤海街道 微软科通大厦21B</div>
        </div>
        <div class="content-third-item">
          <div>
            成都：武侯区天府二街萃华路89号 成都国际科技节能大厦10层
          </div>
          <div>徐州：新城区秦郡路金融集聚区I号楼 云龙金融大厦15A层</div>
        </div>
        <div class="content-third-item">
          <div>
            新加坡：3 Phillip Street Royal Group Building Singapore 048693
          </div>
          <div></div>
        </div>
      </div>
      <div class="content-forth">
        <div>{{ `© 2018-${currentYear} 上海非凸智能科技有限公司` }}</div>
        <div class="beian">
          <img src="../assets/img/营业执照.png" alt="" />
          <img src="../assets/img/备案图标.png" alt="" />

          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009929"
            style="margin-left: 8px"
            >沪公网安备 31010402009929号</a
          >
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/"
            style="margin-left: 8px"
            >沪ICP备2021021911号-1</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import preval from "babel-plugin-preval/macro";
import ways01 from "../assets/img/home/ways-01.png";
import ways02 from "../assets/img/home/ways-02.png";
import ways03 from "../assets/img/home/ways-03.png";
import weChat from "../assets/img/wechat.png";
import douyin from "../assets/img/抖音.png";
import shangwu from "../assets/img/商务合作.jpg";
import pingpai from "../assets/img/品牌合作.jpg";
import qiuzhi from "../assets/img/招聘求职.jpg";

export default {
  name: "Footer",
  data() {
    const currentYear = preval`module.exports = new Date().getFullYear()`;
    return {
      currentYear,
      baseStyle: {
        "max-width": "277px",
      },
      ways01,
      ways02,
      ways03,
      weChat,
      douyin,
      shangwu,
      pingpai,
      qiuzhi,
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.75rem 1.5rem 0.75rem;

  @media screen and (max-width: 1360px) {
    padding: 64px 20px 32px 20px;
  }

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: #fff;
    }
  }

  .footer-content {
    max-width: 1360px;
    width: 100%;
    color: #fff;

    .content-first {
      display: flex;
      flex-wrap: wrap;
      gap: 48px;
      padding-bottom: 24px;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);

      .first-item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 24px;
        > img {
          width: 64px;
          height: 64px;
          object-fit: cover;
          border-radius: 12px;
          background-color: #e8f2ff;
        }

        .item-right {
          display: flex;
          .title {
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            line-height: 26px;
            margin-bottom: 12px;
          }
          .sub-title {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }

          img {
            width: 36px;
            margin-left: 48px;
          }
        }
      }
    }

    .content-second {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 54px;
      --item-width: calc((100% - 160px) / 2);

      @media screen and (max-width: 850px) {
        .second-left,
        .second-right {
          width: 100% !important;
        }
      }

      .second-left {
        width: calc((100% - 160px) / 2);
        > img {
          width: 125px;
          height: 32px;
          margin-bottom: 24px;
        }

        .contact-phone {
          display: flex;
          width: 100%;
          justify-content: space-between;
          flex-wrap: wrap;

          > div {
            display: flex;
            gap: 4px;
            flex-wrap: nowrap;
            div {
              color: #fff;
              font-size: 14px;
              white-space: nowrap;
            }
          }
          .last-link {
            gap: 12px;
            align-items: center;
            a {
              display: inline-flex;
              img {
                width: 20px;
                height: 20px;
                border-radius: 4px;
                cursor: pointer;
              }
            }
          }
        }

        .contact-img-box {
          display: flex;
          flex-wrap: wrap;
          margin-top: 38px;
          justify-content: flex-start;
          gap: 40px;
          .contact-item {
            div:first-child {
              padding: 4px;
              border-radius: 8px;
              border: 0.5px solid #fff;
              background: #ceced3;

              img {
                width: 79px;
                height: 79px;
                border-radius: 4px;
              }
            }
            p {
              text-align: center;
              color: #ffffff80;
              font-size: 10px;
              line-height: 18px;
              margin-top: 4px;
            }
          }
        }
      }

      .second-right {
        display: flex;
        width: calc((100% - 160px) / 2);

        justify-content: space-between;
        .right-item {
          display: flex;
          flex-direction: column;
          gap: 18px;
          color: #ffffff80;
          font-size: 14px;
          line-height: 22px;
          div:first-child {
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }

    .content-third {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 54px;
      gap: 10px;

      @media screen and (max-width: 954px) {
        justify-content: space-between;
        .content-third-item {
          width: 50%;
        }
      }

      @media screen and (max-width: 768px) {
        .content-third-item {
          width: 100%;
        }
      }

      .content-third-item {
        color: #ffffff80;
        max-width: 375px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

        div:first-child {
          margin-bottom: 10px;
        }
      }
    }
    .content-forth {
      text-align: center;
      margin-top: 58px;
      div {
        color: #ffffff80;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
      .beian {
        display: flex;
        justify-content: center;
        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      }
    }
  }

  @media screen and (max-width: map-get($--ft-width, mobile)) {
    .footer-content .content-second .second-left .contact-img-box {
      gap: 1rem;
    }
    .footer-content .content-first .first-item .item-right img {
      display: none;
    }
  }
}
</style>
