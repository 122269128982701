<template>
  <Menubar
    :model="items"
    :class="{ 'ft-navbar': true, 'home-menu': isHome, 'other-menu': !isHome }"
  >
    <template #start>
      <router-link to="/"
        ><img src="../assets/img/logo/logo_white.png" class="logo" height="45"
      /></router-link>
    </template>
    <template #end>
      <div :style="{ '--hoverBgColor': '#0F1490' }">
        <FtButton
          @onClick="onBtnClick"
          style="
            padding: 7px 20px;
            border-radius: 100px;
            background-color: #1c55fb;
            border: none;
            font-size: 14px;
            margin-left: 14px;
          "
          >联系我们
        </FtButton>
      </div>
    </template>
  </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";
import FtButton from "./FtButton.vue";
export default {
  name: "Header",
  components: {
    Menubar,
    FtButton,
  },
  mounted() {
    const nav = document.querySelector(".ft-navbar");
    let lastTop = window.pageYOffset || document.documentElement.scrollTop;

    document.addEventListener("scroll", function () {
      /* console.log(window.pageYOffset || document.documentElement.scrollTop); */
      if ((window.pageYOffset || document.documentElement.scrollTop) >= 100) {
        /* if(document.documentElement.scrollTop >= 100){ */
        // scrolled to the top
        nav.classList.remove("header-top");
        const st = document.documentElement.scrollTop || window.pageYOffset;
        if (st <= lastTop) {
          // scroll up
          nav.style.top = "0px";
          nav.classList.remove("item-not-show");
        } else {
          // scroll down
          nav.style.top = "-70px";
          nav.classList.add("item-not-show");
        }
        lastTop = st;
      } else {
        nav.classList.add("header-top");
      }
    });
  },
  data() {
    return {
      items: [
        {
          label: "关于我们",
          items: [
            {
              label: "公司简介",
              to: "/about/company",
            },
            {
              label: "企业文化",
              to: "/about/culture",
            },
            {
              label: "核心团队",
              to: "/about/team",
            },
            {
              label: "新闻动态",
              to: "/about/news",
            },
            {
              label: "公司活动",
              to: "/about/livestream-poster",
            },
          ],
        },
        {
          label: "产品服务",
          items: [
            {
              label: "非凸基础服务组件",
              items: [
                {
                  label: "智能算法",
                  to: "/product/algorithm",
                },
                {
                  label: "底仓增强算法",
                  to: "/product/底仓增强",
                },
              ],
            },
            {
              label: "非凸智能交易终端",
              to: "/product/platform",
            },
            {
              label: "非凸升级服务组件",
              to: "/product/bus",
            },
          ],
        },
        {
          label: "招贤纳士",
          items: [
            {
              label: "加入我们",
              to: "/join",
            },
            {
              label: "社会招聘",
              to: "/about/social",
            },
            {
              label: "校园招聘",
              to: "/about/campus",
            },
          ],
        },
        {
          label: "商务合作",
          to: "/business",
        },
      ],
      isHome: true,
    };
  },
  watch: {
    $route: {
      handler() {
        const urlName = this.$route.name;
        if (urlName === "Home") {
          this.isHome = true;
        } else {
          this.isHome = false;
          const nav = document.querySelector(".ft-navbar");
          nav.style.visibility = "visible";
        }
      },
    },
  },
  methods: {
    onBtnClick() {
      this.$router.push({
        path: "/business",
      });
    },
  },
};
</script>

<style lang="scss">
.ft-navbar {
  &.p-menubar {
    padding: 0.625rem 6.25rem;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 2000;
    transition: all 0.3s linear;
    border: none;
    border-radius: 0px;
    background: rgba(23, 23, 23, 0.65);
    backdrop-filter: blur(20px);
  }

  @media screen and (max-width: map-get($container-max-widths, lg)) {
    &.p-menubar {
      padding: 0.62rem 0.75rem;
      .p-menubar-button {
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    a.p-menubar-button {
      margin-left: auto;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      &:hover {
        background-color: #ffffff1a;
      }
      i {
        width: 1.5rem;
        height: 1.4rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        overflow: hidden;
        color: #fff;

        &::before{
          height: 1.4rem;
        }
      }
    }

    &.p-menubar .p-menuitem .p-menuitem-text {
      color: #000 !important;
    }

    &.p-menubar .p-menuitem .p-submenu-list {
      background-color: #fff;
    }

    .logo {
      height: 2rem;
    }

    button {
      width: 6rem !important;
      height: 2.25rem !important;
      font-size: 0.875rem !important;
      padding: 0 !important;
    }
  }

  &.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    box-shadow: none;
  }

  .p-menuitem-icon {
    display: none;
  }

  &.p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover,
  &.p-menubar .p-menuitem-link:not(.p-disabled):hover,
  &.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover,
  &.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background-color: transparent;
  }

  &.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link,
  &.p-menubar
    .p-menubar-root-list
    > .p-menuitem.p-menuitem-active
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #ffffff1a !important;
  }

  ul.p-menubar-root-list {
    margin-left: auto;

    .p-menuitem-text {
      color: #fff !important;
      font-size: 14px;
    }
  }

  .p-menubar-end {
    margin-left: unset;
  }

  .p-menuitem-link {
    padding: 9px 16px;

    .p-menuitem-text {
      font-size: 14px !important;
    }
  }

  .logo {
    height: 40px;
  }
}

.home-menu {
  ul.p-submenu-list {
    top: 60px;
    border: 0.5px solid rgba(53, 50, 50, 0.5);
    border-radius: 4px;
    background-color: #17171780;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

    li a {
      span.p-menuitem-text {
        color: #1d2129;
      }
      &:hover {
        background-color: #ffffff1a !important;
      }
    }
  }
}

.other-menu {
  ul.p-submenu-list {
    top: 60px;
    border-radius: 4px;
    border: 0.5px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    li a {
      span.p-menuitem-text {
        color: #1d2129 !important;
      }
      &:hover {
        background-color: #e8f2ff !important;
      }
    }
  }
}

.item-not-show {
  .p-menubar-root-list > .p-menuitem-active > .p-submenu-list {
    display: none !important;
  }
}
</style>
