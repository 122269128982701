<template>
  <button ref="ftButton" class="ft-button" @click="onBtnClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: "FtButton",
  mounted() {
    this.$refs.ftButton.addEventListener("mouseout", (e) => {
      e.target.classList.add("animationOnce");
      setTimeout(() => {
        e.target.classList.remove("animationOnce");
      }, 100);
    });
  },
  methods: {
    onBtnClick: function () {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  color: var(--color, #fff);
  background: transparent;
  cursor: pointer;
  transition: all ease-out 0.5s, border-color ease-out 0.1s;
  border: 1px solid var(--borderColor, #1c55fb);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: inset 0 0 0 0 var(--hoverBgColor, #1c55fb);

  &:hover {
    color: var(--hoverTextColor, #fff) !important;
    border-color: var(--borderHoverColor, #1c55fb) !important;
    box-shadow: inset 0 -100px 0 0 var(--hoverBgColor, #1c55fb) !important;
  }
}

.animationOnce {
  transition: all ease-out 0.5s, border-color ease-out 1.5s;
}
</style>
